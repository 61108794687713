import { Auth } from "aws-amplify";


// Returns user data such as id, name, email
export async function checkUser() {
    let user = await Auth.currentAuthenticatedUser();     
    return user
}


// Verifies user session to check if it's authenticated
export const verifySession = async () => { 
    let jwt;
    await Auth.currentSession()
    .then(data => {
        // the user session infos will be stored in the data variable.
        // to recover jwt token we'll use "data.getIdToken().getJwtToken()". 
        jwt = data.getIdToken().getJwtToken();    
        console.log(jwt) 
             
    }) 
    .catch(error => {
        // in case session is not OK it will throw an error
        console.log('Erro ao recuperar a sessão:', error);
        jwt = 'Erro ao recuperar a sessão'
    });

    // return JWT token which is responsible for authenticating in every request to back-end
    // JWT will be returned as a string
    return jwt
}

export const signInWithAzure = () => { 
 
  try { 
    // Construct the OAuth URL
    const url = `https://isesurvey.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=38pohfaqj3jl81fp5djui0rket&response_type=code&scope=email+openid+phone&redirect_uri=https://survey2.0.ise.org.br/dashboard`;

    // Redirect the user directly to the Azure AD login page
    window.location.href = url;
  } catch (error) {
    console.error(error);
  }
};